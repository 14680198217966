<script setup>
	defineOptions({
		inheritAttrs: false
	})
	const props = defineProps({
		label: {
			type: String,
			default: null
		},
		outerClass: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: 'text'
		},
		filled: {
			type: Boolean,
			default: false,
		},
		errors: {
			type: Array,
			default: () => []
		},
	});
	const model = defineModel({
		type: String,
		required: true,
	});
	// defineEmits(['update:modelValue']);
</script>

<template>
	<div v-if="filled" class="relative flex flex-col flex-auto w-full pointer-events-none"
		:class="outerClass">
		<label
			class="absolute max-w-full px-1 overflow-hidden text-xs font-semibold text-primary left-3 top-1.5 whitespace-nowrap text-ellipsis">
			{{ label }}
		</label>
		<textarea v-if="type === 'textarea'" v-model="model" v-bind="$attrs"
			class="px-3.5 pb-4 pt-5 w-full border resize-none bg-grey-16 border-transparent text-sm rounded-lg overflow-hidden">
        </textarea>
		<select v-else-if="type === 'select'" v-model="model" v-bind="$attrs"
			class="px-3.5 pb-4 pt-5 w-full border bg-grey-16 border-transparent text-sm rounded-lg overflow-hidden">
			<slot />
		</select>
		<input v-else v-model="model" v-bind="$attrs" :type="type"
			class="px-3.5 pb-4 pt-5 w-full border bg-grey-16 border-transparent text-sm rounded-lg overflow-hidden">
		<div v-if="errors.length" class="absolute pt-1 text-xs text-red-500 left-1">{{ errors }}</div>
	</div>
	<div v-else class="relative flex flex-col flex-auto w-full" :class="outerClass">
		<label
			class="absolute max-w-full px-1 overflow-hidden text-xs font-semibold bg-white text-secondary left-4 -top-2 whitespace-nowrap text-ellipsis">
			{{ label }}
		</label>
		<textarea v-if="type === 'textarea'" v-model="model" v-bind="$attrs"
			class="px-3.5 py-4 w-full border border-[#919EAB33] rounded-lg resize-none text-sm overflow-hidden">
        </textarea>
		<select v-else-if="type === 'select'" v-model="model" v-bind="$attrs"
			class="px-3.5 py-4 w-full border border-[#919EAB33] rounded-lg resize-none text-sm overflow-hidden">
			<slot />
		</select>
		<input v-else v-model="model" v-bind="$attrs" :type="type"
			class="px-3.5 py-4 w-full border border-[#919EAB33] text-sm rounded-lg overflow-hidden">
		<div v-if="errors.length" class="absolute pt-1 text-xs text-red-500 left-1">{{ errors }}</div>
	</div>
</template>

<style scoped></style>
